import "@formatjs/intl-numberformat/polyfill";
import { CURRENCIES } from "./constants";
import { VehicleInput } from "../graphql/generated/graphql";

export const classNames = (...classes) => {
  return classes.filter(Boolean).join(" ");
};

export const formatPrice = (
  amount: number,
  currency: string = "CRC",
  locale: string = "es-CR"
) => {
  try {
    var formatter = new Intl.NumberFormat(locale, {
      // style: "currency",
      // currency: currency,
      // currencyDisplay: "symbol", // "narrowSymbol",
      // These options are needed to round to whole numbers if that's what you want.
      minimumFractionDigits: 0, // (this suffices for whole numbers, but will print 2500.10 as $2,500.1)
      maximumFractionDigits: 0, // (causes 2500.99 to be printed as $2,501)
    });

    return `${CURRENCIES[currency].symbol}${formatter.format(amount)}`;
  } catch {
    return `${CURRENCIES[currency].symbol}${amount}`;
  }
};

export const formatNumber = (amount: number, locale: string = "es-CR") => {
  var formatter = new Intl.NumberFormat(locale, {
    // These options are needed to round to whole numbers if that's what you want.
    minimumFractionDigits: 0, // (this suffices for whole numbers, but will print 2500.10 as $2,500.1)
    maximumFractionDigits: 0, // (causes 2500.99 to be printed as $2,501)
  });

  return formatter.format(amount);
};

export const formatPlate = (plate: string) => {
  return `*****${plate.substring(plate.length - 1)}`;
};

/**
 * @deprecated To be removed after migration to Cloudflare images
 */
export const getThumbs = (pictures: string[]) => {
  return pictures.map((pictureUrl) => {
    if (pictureUrl?.includes("res.cloudinary.com")) {
      const paths = pictureUrl.split(process.env.NEXT_PUBLIC_CLOUDINARY_PATH);
      return parseHEICtoWebP(
        `${paths[0]}${process.env.NEXT_PUBLIC_CLOUDINARY_PATH}t_gallery_size/${paths[1]}`
      );
    }

    return pictureUrl;
  });
};

export const capitalizeAllWords = (str: string = "") => {
  if (str && str.length) {
    const lowerCaseStr = str.toLowerCase();
    const words = lowerCaseStr.split(" ");

    for (let i = 0; i < words.length; i++) {
      words[i] = words[i][0]?.toUpperCase() + words[i].substring(1);
    }

    return words.filter((w) => w !== "undefined").join(" ");
  }

  return "";
};

export const isExpired = (d: number) => {
  return d < Date.now();
};

export const cleanupPhoneNumber = (phone: string) => {
  return phone?.replace("506", "").replace("-", "");
};

export const formatDate = (dateStr: string) => {
  return new Date(dateStr).toLocaleDateString("es");
};

export const generateSlug = (param: string) =>
  param
    .toLowerCase()
    .replaceAll(" ", "-")
    .normalize("NFD")
    .replace(/[\u0300-\u036f]/g, "");

export const generateVehicleSlug = (vehicle: VehicleInput, tier: string) => {
  const { brand, model, year, province, municipality, transmission, fuel } =
    vehicle;
  return `${generateSlug(brand)}-${generateSlug(model)}-${year}-${generateSlug(
    province
  )}-${generateSlug(municipality)}-${transmission}-${fuel}${
    tier ? `-${tier}` : ""
  }-${Date.now().toString()}`;
};

/**
 * @deprecated To be removed after migration to Cloudflare images
 */
export const parseHEICtoJPEG = (url: string) =>
  url?.replace(".webp", ".jpeg").replace(".heic", ".jpeg");

/**
 * @deprecated To be removed after migration to Cloudflare images
 */
export const parseHEICtoWebP = (url: string) => url?.replace(".heic", ".webp");

/**
 * @deprecated To be removed after migration to Cloudflare images
 */
export const replaceImageExtensionToWebP = (url) => {
  return url.replace(/\.(jpg|jpeg|png)$/i, ".webp");
};

/**
 * @deprecated To be removed after migration to Cloudflare images
 */
export const replaceImageTransformation = (
  url: string,
  transformation?: string
) => {
  if (!transformation) return url;

  return url.replace("t_gallery_size", transformation);
};
